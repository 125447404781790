/**
 * Header interface interactions and accessiblity
 * @param {*} $
 */

const header = (() => {
  function isIosSafari() {
    // check if user is on iOS Safari
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    return iOSSafari;
  }

  function iOSPositionBodyWhenNavExpanded() {
    if (
      isIosSafari &&
      $('.alert').length &&
      $('.header').hasClass('js-active-nav')
    ) {
      $('body').removeAttr('style');

      // eslint-disable-next-line no-inner-declarations
      function offset(el) {
        const rect = el.getBoundingClientRect();
        const scrollLeft =
          window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        return {
          top: rect.top + scrollTop,
          left: rect.left + scrollLeft,
        };
      }

      const div = document.querySelector('.header');
      const divOffset = offset(div);

      $('body').css({
        top: -1 * divOffset.top,
      });
    }
  }

  // Open / close main navigation
  function toggleHeaderNav() {
    const $header = $('.header');
    const $body = $('body');
    const $html = $('html');
    const $headerNavToggleBtn = $('.header-navigation-toggle');
    const $headerNavigation = $('.header-navigation');
    const $headerNavRight = $('.header-navigation__right');
    const $headerNavMainListItem = $('.header-navigation__main-list-item');

    // if the header is in search state we must close the header search first
    if ($header.hasClass('js-active-search')) {
      toggleHeaderSearch();
    }

    if ($('.alert').length) {
      if (!$('header').hasClass('js-sticky')) {
        $('html, body').animate(
          {
            scrollTop: Math.ceil($('.header').offset().top),
          },
          0,
        );
      }
    }

    if (isIosSafari) {
      // fixing a bug in iOS Safari where opening the main nav scrolls the user back to the top
      if ($('.alert').length) {
        $('body').removeAttr('style');

        // eslint-disable-next-line no-inner-declarations
        function offset(el) {
          const rect = el.getBoundingClientRect();
          const scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft;
          const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
          return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft,
          };
        }

        // example use
        const div = document.querySelector('.header');
        const divOffset = offset(div);

        $body.css({
          top: -1 * divOffset.top,
        });
      } else {
        $html.css('height', $html.height());
      }
    }

    $headerNavToggleBtn.focus();

    $header.toggleClass('js-active-nav');
    
    if ($header.hasClass('js-active-nav')) {
      // adding js-fixed to $body after 100ms to wait for other opened things to close that would also add / remove js-fixed from $body such as the interior nav
      if (!$body.hasClass('js-fixed')) {
        setTimeout(() => {
          $body.addClass('js-fixed');
        }, 100);
      }

      // add classes for animations
      $headerNavToggleBtn.attr('aria-label', 'Close');

      setTimeout(() => {
        $headerNavigation.addClass('fade-in');
      }, 300);

      setTimeout(() => {
        $headerNavRight.addClass('fade-in');
      }, 600);

      setTimeout(() => {
        $headerNavMainListItem.each(function (i) {
          setTimeout(() => {
            $(this).addClass('fade-in');
          }, 50 * (i + 1));
        });
      }, 600);
    } else {
      // removing classes for animations
      $headerNavToggleBtn.attr('aria-label', 'Open');
      $headerNavigation.removeClass('fade-in');
      $headerNavRight.removeClass('fade-in');
      $headerNavMainListItem.removeClass('fade-in');

      $body.removeClass('js-fixed');

      if (isIosSafari()) {
        $html.removeAttr('style');
        $body.removeAttr('style');
      }
    }
  }

  // Expand / collapse sub navigation menus
  function toggleSubNav(e) {
    const $subNavToggler = $(e.target);
    const $subNav = $(e.target.parentNode.nextSibling.nextSibling);

    $subNavToggler.toggleClass('js-active');
    $subNav.slideToggle();

    if ($subNavToggler.hasClass('js-active')) {
      $subNavToggler.attr('aria-expanded', 'true');
      $subNavToggler.children('span').text('Collapse');
      $subNav.attr('aria-hidden', 'false');
    } else {
      $subNavToggler.attr('aria-expanded', 'false');
      $subNavToggler.children('span').text('Expand');
      $subNav.attr('aria-hidden', 'true');
    }
  }

  // Toggle header search state
  function toggleHeaderSearch() {
    const $header = $('.header');
    const $search = $('#search');
    const $searchSubmitBtn = $('.header-search__submit');
    const $searchToggleBtn = $('.header-search-toggle');
    const $searchForm = $('.header-search__form');

    // if the main nav is open then we must close that first
    if ($header.hasClass('js-active-nav')) {
      toggleHeaderNav();
    }

    $header.toggleClass('js-active-search');

    if ($header.hasClass('js-active-search')) {
      $search.focus();
      $search.attr('tabindex', '0');
      $searchSubmitBtn.attr('tabindex', '0');
      $search.attr('aria-hidden', 'false');
      $searchSubmitBtn.attr('aria-hidden', 'false');
      $searchToggleBtn.attr('aria-label', 'Collapse');
      $searchForm.attr('aria-hidden', 'false');
    } else {
      $searchToggleBtn.focus();
      $search.attr('tabindex', '-1');
      $searchSubmitBtn.attr('tabindex', '-1');
      $search.attr('aria-hidden', 'true');
      $searchSubmitBtn.attr('aria-hidden', 'true');
      $searchToggleBtn.attr('aria-label', 'Expand');
      $searchForm.attr('aria-hidden', 'true');
    }
  }

  // this function will only handle functionality for closing other expanded sections and will not handle actually opening the internal navigation
  function closeExpandedSections() {
    const $header = $('.header');

    if ($header.hasClass('js-active-nav')) {
      // if the main nav is open then we must close that
      toggleHeaderNav();
    } else if ($header.hasClass('js-active-search')) {
      // else if search is open then we will close that
      toggleHeaderSearch();
    }
  }

  // Handles sticky header functionality while user scrolls up / down on a page
  function stickyHeaderScroll() {
    const $window = $(window);
    const $body = $('body');
    const $header = $('.header');
    const $headerContainer = $('.header-container');
    let lastScrollTop = 0;

    $window.on('scroll', function () {
      const scrollTop = $window.scrollTop();
      if (!$('.header').hasClass('js-active-nav')) {
        if (scrollTop < lastScrollTop) {
          // user scrolling up
          $header.addClass('js-sticky');
          $headerContainer.removeClass('js-hide');

          // removing js-sticky from the header when it is at the top of the screen
          // accounting for space of alerts on the page if they are present
          if ($body.hasClass('has-alert')) {
            if (
              scrollTop <=
              $headerContainer.outerHeight() + $('.alert').outerHeight()
            ) {
              $header.removeClass('js-sticky');
            }
          } else {
            if (scrollTop <= 0) {
              $header.removeClass('js-sticky');
            }
          }
        } else {
          // user scrolling down
          // making sure the header doesn't perform a slide up while in view at each breakpoint
          if ($body.hasClass('has-alert')) {
            if ($window.innerWidth() >= 1024) {
              if (scrollTop <= 80 + $('.alert').outerHeight()) {
                return;
              }
            } else if ($window.innerWidth() >= 700) {
              if (scrollTop <= 60 + $('.alert').outerHeight()) {
                return;
              }
            } else {
              if (scrollTop <= 50 + $('.alert').outerHeight()) {
                return;
              }
            }
          } else {
            if ($window.innerWidth() >= 1024) {
              if (scrollTop <= 80) {
                return;
              }
            } else if ($window.innerWidth() >= 700) {
              if (scrollTop <= 60) {
                return;
              }
            } else {
              if (scrollTop <= 50) {
                return;
              }
            }
          }

          $headerContainer.addClass('js-hide');

          // adding a delay in removing the js-sticky so the slide up animation can play to completion
          if ($header.hasClass('js-sticky')) {
            setTimeout(() => {
              $header.removeClass('js-sticky');
            }, 300);
          }
        }
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    });
  }

  // If the header search is active and the user has clicked outside of the header search container, we will close the header search
  function checkIfSearchShouldBeClosed(e) {
    if (!$('.header').hasClass('js-active-search')) {
      return;
    }

    const $container = $('.header-search-toggle__container');
    const $target = $(e.target);

    if (!$.contains($container[0], $target[0])) {
      toggleHeaderSearch();
    }
  }

  // Handles tab looping and accessible updates to header for improved keyboard navigation
  function headerKeyboardNavigation(e) {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
    const isHeaderNavOpen = $('.header').hasClass('js-active-nav');
    const isHeaderSearchOpen = $('.header').hasClass('js-active-search');
    const $focused = $(':focus');

    if (e.key === 'Escape') {
      if ($('.header-navigation-toggle').attr('aria-label') === 'Close') {
        $('.header-navigation-toggle').trigger('click');
      }
    }

    if (!isTabPressed) {
      return;
    }

    let $firstFocusableElement;
    let $lastFocusableElement;

    if (isHeaderNavOpen) {
      // tab loop for main site navigation
      $firstFocusableElement = $('.header-logo__link');
      $lastFocusableElement = $(
        '.header-navigation__secondary-menus-ancillary li:last-of-type > a',
      );

      if (e.shiftKey) {
        // shift + tab was pressed - looping through items backwards
        if ($focused.is($firstFocusableElement)) {
          $lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        // tab was pressed - looping through items forwards
        if ($focused.is($lastFocusableElement)) {
          $firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    } else if (isHeaderSearchOpen) {
      // accessibility keyboard controls for header search
      $firstFocusableElement = $('#search');
      $lastFocusableElement = $('.header-search__submit');

      if (e.shiftKey) {
        // if we tab backwards off of $firstFocusableElement (outside of the search container), then we'll close the search
        if ($focused.is($firstFocusableElement)) {
          toggleHeaderSearch();
        }
      } else {
        // if we tab forwards off of $lastFocusableElement (also outside of the search container), then we'll close the search
        if ($focused.is($lastFocusableElement)) {
          e.preventDefault();
          toggleHeaderSearch();
          $('.header-search-toggle').focus();
        }
      }
    }
  }

  const init = () => {
    $('.header-navigation-toggle').on('click', toggleHeaderNav);
    $('.header-navigation__sub-nav__toggle').on('click', toggleSubNav);
    $('.header-search-toggle').on('click', toggleHeaderSearch);
    $('.internal-navigation-btn').on('click', closeExpandedSections);
    $(window).on('load', stickyHeaderScroll);
    $(window).on('click', checkIfSearchShouldBeClosed);
    $(document).on('keydown', headerKeyboardNavigation);
    $(window).on('resize', iOSPositionBodyWhenNavExpanded);
  };

  return {
    init,
  };
})();

export default header;
