/**
 * Why CalWest Section display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Why CalWest Section present
 */

import { clearConfigCache } from "prettier";

const whyCWSL = (() => {
  // Expands specified Why CWSL "Details" Section
  function showDetails(section) {
    const $section = $(section);
    const $hiddenSectionContainer = $section.find('.why-cwsl__outer');
    const $button = $section.find('.why-cwsl__toggle-btn');
    const $buttonHiddenText = $section.find('.why-cwsl__toggle-btn > span');
    const $companionText = $section.find('.why-cwsl__toggle > span');
    const $slick = $section.find('.slick-slider');

    $button.attr('aria-expanded', 'true');
    $buttonHiddenText.html('Hide Details');
    $companionText.html('Hide Details');

    $section.toggleClass('js-expanded');

    // fixes a visual issue with slick slider being initialized in a display:none container
    if ($slick.length > 0) {
      $slick.slick('setPosition');
    }

    setTimeout(() => {
      $hiddenSectionContainer.toggleClass('js-fade');
      
      $('html, body').animate({
        scrollTop: $section.find('.why-cwsl__toggle-btn').offset().top - 50,
      }, 300); // scrolling down to newly opened section
    }, 300); // letting fade animation play
  }

  // Collapses specified Why CWSL "Details" Section
  function hideDetails(section) {
    const $section = $(section);
    const $hiddenSectionContainer = $section.find('.why-cwsl__outer');
    const $button = $section.find('.why-cwsl__toggle-btn');
    const $buttonHiddenText = $section.find('.why-cwsl__toggle-btn > span');
    const $companionText = $section.find('.why-cwsl__toggle > span');
    
    $button.attr('aria-expanded', 'false');
    $buttonHiddenText.html('Show Details');
    $companionText.html('Show Details');

    $hiddenSectionContainer.toggleClass('js-fade');

    setTimeout(() => {
      $section.toggleClass('js-expanded');
    }, 300); // letting the fade animation play before closing section
  }

  // Handles conditions for toggling "Details" sections
  function toggleDetails(e) {
    const clickedBtn = e.target; // button.why-cwsl__toggle-btn
    const parentSection = clickedBtn.parentNode.parentNode.parentNode.parentNode.parentNode; // section.why-cwsl
    const $parentSection = $(parentSection);
    
    if ($parentSection.hasClass('js-expanded')) {
      hideDetails(parentSection); // if the section we're toggling is already opened, we are closing it now
    } else {
      showDetails(parentSection); // otherwise we'll be opening the section
    }
  }

  function setupWaypoints() {
    const $whySections = Array.prototype.slice.apply(
      document.querySelectorAll('section.why-cwsl'),
    );

    $whySections.forEach((whySection) => {
      // eslint-disable-next-line no-undef
      const sectionWaypoint = new Waypoint({
        element: whySection,
        handler(direction) {
          whySection.classList.add('js-animate');
        },
        offset: '70%',
      });
    });
  }

  const init = () => {
    if (!$('.why-cwsl').length) {
      return;
    }

    $('.why-cwsl__toggle--wrapper').on('click', toggleDetails);
    $(window).on('load', setupWaypoints);
  };

  return {
    init,
  };
})();

export default whyCWSL;
