const deepLink = (() => {
  const init = () => {
    $(window).on('load', () => {
      let $hash = window.location.hash;

      if ($hash !== '') {
        $hash = window.location.hash.substring(1);
        const $id = $(`#${$hash}`);

        if ($id.length) {
          const $scrollPos = document
            .querySelector(`#${$hash}`)
            .getBoundingClientRect().top;

          document.querySelector(`#${$hash}`).scrollIntoView({
            behavior: 'smooth',
          });

          setTimeout(() => {
            if (
              $id.hasClass('accordion__toggle') &&
              $id.attr('aria-expanded') == 'false'
            ) {
              $id.focus();
              $id.click();
            }
          }, 150);
        }
      }
    });
  };

  return {
    init,
  };
})();

export default deepLink;
