/**
 * Your Journey display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if accordion present
 */

 const yourJourney = (() => {
  function updateNavigationHighlights(sectionString) {
    const selector = `[data-section="${sectionString}"]`;
    const btn = document.querySelectorAll(selector)[0];

    $('.js-highlighted').removeClass('js-highlighted');
    $(btn).addClass('js-highlighted');
  }

  function setupWaypoints() {
    $('body').addClass('js-available');
    const $window = $(window);
    const $sectionItems = Array.prototype.slice.apply(
      document.querySelectorAll('.your-journey-section__item'),
    );
    const sections = ['before', 'year-1', 'year-2', 'year-3', 'after'];
    let topOffsetPercentage;
    let bottomOffsetPxThreshold;

    if ($window.width() >= 700) {
      topOffsetPercentage = '30%';
      bottomOffsetPxThreshold = ($window.innerHeight() * 0.3);
    } else {
      topOffsetPercentage = '50%';
      bottomOffsetPxThreshold = ($window.innerHeight() * 0.5);
    }

    sections.forEach(function(section) {
      // eslint-disable-next-line no-undef
      const topOffsetWaypoint = new Waypoint({
        element: document.getElementById(section),
        handler(direction) {
          if (direction == 'down') {
            updateNavigationHighlights(section);
          }
        },
        offset: topOffsetPercentage,
      });
      // eslint-disable-next-line no-undef
      const bottomOffsetWaypoint = new Waypoint({
        element: document.getElementById(section),
        handler(direction) {
          if (direction == 'up') {
            updateNavigationHighlights(section);
          }
        },
        offset() {
          const sectionOffsetTop = this.element.getBoundingClientRect().top + window.scrollY;
          const sectionHeight = this.element.offsetHeight;
          const sectionBottom =  sectionOffsetTop + sectionHeight;
          const sectionOffsetPx = sectionBottom - bottomOffsetPxThreshold;

          return -this.element.clientHeight;
        },
      });
    });

    $sectionItems.forEach((sectionItem) => {
      // eslint-disable-next-line no-undef
      const sectionItemWaypoint = new Waypoint({
        element: sectionItem,
        handler(direction) {
          sectionItem.classList.add('js-show');
        },
        offset: '70%',
      });
    });
  }

  function scrollToSection(e) {
    const $clickedBtn = $(e.target);
    const sectionId = $clickedBtn.data('section');
    const selector = "#" + String(sectionId);

    $('html, body').animate({
      scrollTop: $(selector).offset().top
    }, 1000);

    $(selector).focus();
  }

  function stickyNavScroll() {
    const $window = $(window);
    const $yourJourneyContent = $('.your-journey-content');
    const $nav = $('.your-journey-navigation');
    let lastScrollTop = 0;

    if ($window.width() >= 700) {
      return;
    }

    $window.on('scroll', function() {
      const yjcOffsetTop = $yourJourneyContent.offset().top;
      const scrollTop = $window.scrollTop() - yjcOffsetTop;

      if (scrollTop < lastScrollTop) { // user scrolling up
        $nav.removeClass('js-hide');
      } else { // user scrolling down
        $nav.addClass('js-hide');
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    });
  }

  function updateSidebarZIndex() {
    const $header = $('.header');
    const $sidebar = $('.your-journey-navigation');

    if ($header.hasClass('js-active-nav')) {
      $sidebar.css('z-index', '0');
    } else {
      $sidebar.removeAttr('style');
    }
  }

  const init = () => {
    if (!$('.your-journey-container').length) {
      return;
    }

    $('.your-journey-navigation-btn').on('click', scrollToSection);
    $(window).on('load', setupWaypoints);
    $(window).on('load', stickyNavScroll);
    $('.header-navigation-toggle').on('click', updateSidebarZIndex);
  };

  return {
    init,
  };
})();

export default yourJourney;
