import '@babel/polyfill';
import '@accessible360/accessible-slick';
import 'object-fit-images';
import Waypoint from 'waypoints/lib/noframework.waypoints.min.js';

import header from './interface/header';
import deepLink from './interface/deep-link';

import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
//import instagramLoader from './object/instafeed';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import facultyTable from './object/faculty-table';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import calendar3up from './object/calendar-3up';
import catalog from './object/catalog';
import tableScroll from './object/scroll-table';
import lastWord from './object/last-word';
import backToTop from './object/back-to-top';
import interiorNav from './object/interior-navigation';
import spotlight from './object/spotlight';
import homeNav from './object/home-nav';
import steps from './object/steps';

import whyCWSL from './type/why-cwsl';
import yourJourney from './type/your-journey';
import blog from './type/blog';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  header.init();
  deepLink.init();
  accordion.init();
  inlineVideo.init();
  //instagramLoader.init();
  instagram.init();
  twitterLoader.init();
  facultyTable.init();
  calendar.init();
  calendar3up.init();
  catalogProgram.init();
  carousel.init();
  programFinder.init();
  catalog.init();
  tableScroll.init();
  lastWord.init();
  backToTop.init();
  interiorNav.init();
  spotlight.init();
  objectFitImages();
  whyCWSL.init();
  yourJourney.init();
  blog.init();
  homeNav.init();
  steps.init();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
    }, 100);
  });
});
