/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

import { each } from "jquery";

const carousel = (() => {
  // Render carousel
  function getCarouselWrapperAriaLabel($carousel) {
    if ($carousel.attr('aria-label')) {
      return String($carousel.attr('aria-label'));
    } else {
      return 'carousel';
    }
  }

  function renderCarousel() {
    $('.carousel__wrapper').each(function() {
      $(this).slick({
        dots: false,
        infinite: true,
        slidesToShow: 1,
        regionLabel: getCarouselWrapperAriaLabel($(this)),
      });
    });
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;
