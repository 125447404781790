const lastWord = (() => {
  function addLastWordSpanFromText(selector, timeout) {
    setTimeout(() => {
      $(selector).each((i, elem) => {
        // eslint-disable-next-line max-nested-callbacks
        $(elem).html(function () {
          // separate the text by spaces
          const $this = $.trim($(this).text());
          const text = $this.split(' ');
          // drop the last word and store it in a variable
          const last = text.pop();
          // join the text back and if it has more than 1 word add the span tag to the last word
          return `${text.join(
          ' ',
          )} <span class="last-word">${last}<span></span></span>`;
        });
      }); 
    }, timeout);
  }

  function addLastWordSpanFromHTML(selector, timeout) {
    setTimeout(() => {
      $(selector).each((i, elem) => {
        // eslint-disable-next-line max-nested-callbacks
        $(elem).html(function () {
          // separate the text by spaces
          const $this = $.trim($(this).html());
          const text = $this.split(' ');
          // drop the last word and store it in a variable
          const last = text.pop();
          // join the text back and if it has more than 1 word add the span tag to the last word
          return `${text.join(
          ' ',
          )} <span class="last-word">${last}<span></span></span>`;
        });
      }); 
    }, timeout);
  }

  const init = () => {
    const textSelectors =
      '.cta--button, .cta--link, .cta__link-vertical-border, .cta--button-blue, .column__title a, .cta__link-vertical-border__teal, .spotlight-item__header a, .cta__link-vertical-border__red, .cta--link-red, .cta--link-green, .cta--link-orange, .feature__title-secondary a, .cta__link-vertical-border__orange, .breadcrumbs > ul > li > a';
    const htmlSelectors =
      '.story-display__quote, .pc-quote__blockquote, blockquote.quote .quote__copy';

    $(document).ready(function () {
      // adds last word spans to any of the 'selectors' elements site-wide
      addLastWordSpanFromText(textSelectors, 150);
      addLastWordSpanFromHTML(htmlSelectors, 150);

      // specifically for the Faculty Directory page
      if ($('.faculty--directory').length) {
        const facultyTableSelectors = '#faculty-table a.link-name';

        // these elements need extra time until they're loaded on the page
        addLastWordSpanFromText(facultyTableSelectors, 600);

        // adding last word span to elements that are displayed on page via pagination
        $(document).on('click', function (e) {
          const $target = $(e.target);

          if ($target.hasClass('paginate_button')) {
            addLastWordSpanFromText(facultyTableSelectors, 100);
          }
        });
      }
    });
  };

  return {
    init,
  };
})();

export default lastWord;