/**
 * Home Navigation display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Home Navigation present
 */

const homeNav = (() => {
  function transitionToggle($el) {
    $($el).css({
      transition: 'all 1s'
    });

    setTimeout(() => {
      $($el).removeAttr('style');
    }, 1000);
  }

  // Scroll trigger to toggle home hero state of navigation (i.e. icons overlayed over the hero)
  function scrollTriggerHero() {
    const topofDiv = $('.home-hero').offset().top;
    const height = $('.home-hero').outerHeight() * 0.3;

    $(window).on('load scroll', () => {
      if ($(window).scrollTop() > topofDiv + height) {
        if ($('.home-nav').hasClass('js-hero')) {
          $('.home-nav').removeClass('js-hero');
          transitionToggle('.home-nav__items');
        }
      } else if (!$('.home-nav').hasClass('js-hero')) {
        $('.home-nav').addClass('js-hero');
        transitionToggle('.home-nav__items');
      }

      if ($('.home-nav').hasClass('js-hero')) {
        $('.home-nav-item__bttn').removeClass('js-active');
      }
    });
  }

  function scrollTriggerHomeSection() {
    $(window).on('scroll', () => {
      $('.home-section').each((i, elem) => {
        const topofDiv = $(elem).offset().top;
        const height = $(elem).outerHeight() * 0.7;

        if ($(window).scrollTop() > topofDiv - height) {
          $(elem).addClass('js-active');
        }
      });
    });
  }

  function hoverNavItems() {
    $('.home-nav-item > .home-nav-item__bttn').on('mouseenter', (el) => {
      const index = $('.home-nav-item').index($(el.target).parent());

      if (
        index >= 0 &&
        !$(`.home-hero__imgs > .home-hero__img[data-index="${index}"]`).hasClass('js-active')
      ) {
        $('.home-hero__imgs > .home-hero__img').removeClass('js-active');
        $('.home-hero__imgs > .home-hero__img').attr('aria-hidden', 'true');
        $(`.home-hero__imgs > .home-hero__img[data-index="${index}"]`).addClass('js-active');
        $(`.home-hero__imgs > .home-hero__img[data-index="${index}"]`).attr('aria-hidden', 'false');
      }
    });

    // when we're not hovering over a home hero nav item, we'll default to showing the image that shows on page load
    $('.home-nav-item > .home-nav-item__bttn').on('mouseout', () => {
      $('.home-hero__imgs > .home-hero__img').removeClass('js-active');
      $('.home-hero__imgs > .home-hero__img').attr('aria-hidden', 'true');
      $(`.home-hero__imgs > .home-hero__img[data-index="-1"]`).addClass('js-active');
      $(`.home-hero__imgs > .home-hero__img[data-index="-1"]`).attr('aria-hidden', 'false');
    });
  }

  function toggleNavActiveState() {
    $(window).on('scroll', (e) => {
      $('.home-section').each((i, elem) => {
        const topofDiv = $(elem).offset().top;
        const height = $(elem).outerHeight() * 0.5;

        if ($(window).scrollTop() < $('.home-hero').offset().top + $('.home-hero').outerHeight() * 0.9) {
          $('.home-nav-item').removeClass('js-active');
        } else if ($(window).scrollTop() > topofDiv - height && !$('.home-nav-item').eq(i).hasClass('js-active')) {
          $('.home-nav-item .home-nav-item__bttn').removeClass('js-active');
          $('.home-nav-item').eq(i).find('.home-nav-item__bttn').addClass('js-active');
        }
      });
    });
  }

  function buttonClickEvent() {
    $('.home-nav-item__bttn').on('click', (e)=> {
      e.preventDefault();

      const index = $('.home-nav-item').index($(e.target).parent());
      
      $('html, body').animate(
        {
          scrollTop: $('.home-section').eq(index).offset().top,
        },
        300,
      );

      $('.home-section').eq(index).focus();
    });
  }

  const init = () => {
    if (!$('.home-nav').length) {
      return;
    }

    $('body').addClass('js-home-loaded');

    scrollTriggerHero();
    scrollTriggerHomeSection();
    hoverNavItems();
    toggleNavActiveState();
    buttonClickEvent();
  };

  return {
    init,
  };
})();

export default homeNav;