/**
 * Steps object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Steps pattern is present
 */

const steps = (() => {

  function setupWaypoints() {
    const $steps = Array.prototype.slice.apply(
      document.querySelectorAll('.steps .step'),
    );

    $steps.forEach((step) => {
      // eslint-disable-next-line no-undef
      const stepWaypoint = new Waypoint({
        element: step,
        handler(direction) {
          step.classList.add('js-animate');
        },
        offset: '70%',
      });
    });
  }

  const init = () => {
    if (!$('.steps').length) {
      return;
    }

    $(window).on('load', setupWaypoints);
  };

  return {
    init,
  };
})();

export default steps;
