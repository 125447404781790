/**
 * Spotlight display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if spotlight present
 */
const spotlight = (() => {
  // Render spotlight
  const svg =
    '<svg viewBox="0 0 112 110" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Border</title><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g transform="translate(-456.000000, -8554.000000)" fill="#FFFFFF" stroke="#EB9D00"><g transform="translate(-1.500000, 8388.000000)"><g transform="translate(110.500000, 167.000000)"><g transform="translate(347.000000, 0.000000)"><g transform="translate(1.000000, 0.000000)"><polygon points="55 0 80.5597745 6.29991859 100.264113 23.7564389 109.598988 48.3704826 106.425893 74.5032688 91.4717462 96.1680911 68.1623615 108.4018 41.8376385 108.4018 18.5282538 96.1680911 3.57410665 74.5032688 0.401011925 48.3704826 9.73588738 23.7564389 29.4402255 6.29991859"></polygon></g></g></g></g></g></g></svg>';

  function renderSpotlight100() {
    $(
      '.para-container-item:not(.para-container-item--60, .para-container-item--40) > .spotlight',
    ).each((index, elem) => {
      const $slickSlider = $(elem).find('.spotlight__wrapper');
      const $arrowWrapper = $(elem).find('.spotlight__controller');
      $slickSlider.slick({
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        appendArrows: $arrowWrapper,
        prevArrow: `<button type="button" class="slick-prev"><span>Previous</span>${svg}</button>`,
        nextArrow: `<button type="button" class="slick-next"><span>Next</span>${svg}</button>`,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  }

  function renderSpotlight40() {
    $('.para-container-item--40 > .spotlight').each((index, elem) => {
      const $slickSlider = $(elem).find('.spotlight__wrapper');
      const $arrowWrapper = $(elem).find('.spotlight__controller');
      $slickSlider.slick({
        dots: false,
        infinite: false,
        slidesToShow: 1,
        appendArrows: $arrowWrapper,
        prevArrow: `<button type="button" class="slick-prev"><span>Previous</span>${svg}</button>`,
        nextArrow: `<button type="button" class="slick-next"><span>Next</span>${svg}</button>`,
      });
    });
  }

  const init = () => {
    if ($('.para-container-item--40 > .spotlight').length) {
      renderSpotlight40();
    }

    if (
      $(
        '.para-container-item:not(.para-container-item--60, .para-container-item--40) > .spotlight',
      ).length
    ) {
      renderSpotlight100();
    }
  };

  return {
    init,
  };
})();

export default spotlight;
