/**
 * Accordion-simple displays main navigation
 * @param {*} $
 * @returns {init} Determine and run if main-nav present
 */

// eslint-disable-next-line global-require
require('../vendor/scrollsync.js');

const $ = require('jquery');

const tableScroll = (() => {
  // Add table scroll buttons to DOM
  const $tableScroll = $('table .table-scroller');
  const $tableWrap = $('.table-block__holder');
  const $table = $('.table-block table');

  function tableNext() {
    const elWidth = $(this)
      .siblings($table)
      .parent()
      .width();

    $(this)
      .siblings($table)
      .find($tableScroll)
      .animate(
        {
          scrollLeft: `+=${elWidth}`,
        },
        300,
        'swing',
      );
  }

  function tablePrev() {
    const elWidth = $(this)
      .siblings($table)
      .parent()
      .width();
    $(this)
      .siblings($table)
      .find($tableScroll)
      .animate(
        {
          scrollLeft: `-=${elWidth}`,
        },
        300,
        'swing',
      );
  }

  function tableInit() {
    // determine if each table will show scroll buttons by comparing the table width and the wrap width for every table that appears on the page
    $tableWrap.each(function( index,  tableWrap ) {
      const $tableWrap = $(tableWrap);
      const wrapWidth = $tableWrap.outerWidth();
      const tableWidth = $tableWrap.find('tr').outerWidth();

      if (wrapWidth < tableWidth) {
        $tableWrap.addClass('js-scroll--right');
        // updating aria-hidden attributes for accessibility
        $tableWrap.children('.table-block__next').attr('aria-hidden', 'false');
      } else {
        $tableWrap.removeClass('js-scroll--left js-scroll--right');
        // updating aria-hidden attributes for accessibility
        $tableWrap.children('.table-block__prev').attr('aria-hidden', 'true');
        $tableWrap.children('.table-block__next').attr('aria-hidden', 'true');
      }
    });
  }

  function tableUpdateScroll() {
    const scrollLeft = $(this).scrollLeft();
    const scrollWidth = $(this)[0].scrollWidth - $tableWrap[0].clientWidth;
    const $thisWrapper = $(this).closest('.table-block__holder');
    if (scrollLeft === 0 && scrollWidth > 0) {
      // If at the begin scroll pos
      $thisWrapper.addClass('js-scroll--right');
      $thisWrapper.removeClass('js-scroll--left');
      // updating aria-hidden attributes for accessibility
      $thisWrapper.children('.table-block__next').attr('aria-hidden', 'false');
      $thisWrapper.children('.table-block__prev').attr('aria-hidden', 'true');
    } else if (scrollLeft === scrollWidth && scrollWidth > 0) {
      // If at the end scroll pos
      $thisWrapper.removeClass('js-scroll--right').addClass('js-scroll--left');
      // updating aria-hidden attributes for accessibility
      $thisWrapper.children('.table-block__next').attr('aria-hidden', 'true');
      $thisWrapper.children('.table-block__prev').attr('aria-hidden', 'false');
    } else {
      $thisWrapper.addClass('js-scroll--right js-scroll--left');
      // updating aria-hidden attributes for accessibility
      $thisWrapper.children('.table-block__next').attr('aria-hidden', 'false');
      $thisWrapper.children('.table-block__prev').attr('aria-hidden', 'false');
    }
  }

  const init = () => {
    if ($tableWrap.length) {
      $tableWrap.append(
        `<button class="table-block__prev" aria-hidden="true" style="display: none;">
          Scroll Table Right
          <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
            <title>Polygon Teal</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-137.000000, -259.000000)" fill="#42D1AC">
                <g transform="translate(164.000000, 286.000000) scale(-1, 1) rotate(-90.000000) translate(-164.000000, -286.000000) translate(137.000000, 259.000000)">
                  <polygon points="27 0 37.9818894 2.33427264 47.0649103 8.93347363 52.6785259 18.6565412 53.8520912 29.8222685 50.3826859 40.5 42.8702018 48.8434588 32.6136157 53.4099852 21.3863843 53.4099852 11.1297982 48.8434588 3.6173141 40.5 0.147908825 29.8222685 1.32147406 18.6565412 6.93508971 8.93347363 16.0181106 2.33427264"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </button>\n` +
        `<button class="table-block__next" aria-hidden="true" style="display: none;">
          Scroll Table Left
          <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
            <title>Polygon Teal</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-137.000000, -259.000000)" fill="#42D1AC">
                <g transform="translate(164.000000, 286.000000) scale(-1, 1) rotate(-90.000000) translate(-164.000000, -286.000000) translate(137.000000, 259.000000)">
                  <polygon points="27 0 37.9818894 2.33427264 47.0649103 8.93347363 52.6785259 18.6565412 53.8520912 29.8222685 50.3826859 40.5 42.8702018 48.8434588 32.6136157 53.4099852 21.3863843 53.4099852 11.1297982 48.8434588 3.6173141 40.5 0.147908825 29.8222685 1.32147406 18.6565412 6.93508971 8.93347363 16.0181106 2.33427264"></polygon>
                </g>
              </g>
            </g>
          </svg>
        </button>`,
      );

      setTimeout(() => {
        $tableWrap.children('.table-block__prev, .table-block__next').removeAttr('style');
      }, 500);

      $('.table-block__next').on('click', tableNext);
      $('.table-block__prev').on('click', tablePrev);

      $(window).on('load resize', tableInit);
      $tableScroll.on('scroll', tableUpdateScroll);
    }
  };

  return {
    init,
  };
})();

export default tableScroll;
