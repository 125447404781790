const backToTop = (() => {
  const init = () => {
    if (!$('.footer').length) {
      return;
    }

    function toTop() {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        300,
        () => {
          $('.skip-link').focus();
        },
      );
    }

    $('.footer-backtotop').click(toTop);
  };

  return {
    init,
  };
})();

export default backToTop;