/**
 * Section Navigation display object interactions
 * @param {*} $
 * @returns {init} Determine and run if Section Navigation present
 */

const interiorNav = (() => {
  function setNavDisplay() {
    // closest polyfill - IE11
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        let el = this;

        do {
          if (Element.prototype.matches.call(el, s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      };
    }

    $('.section-nav__list .active-parent').removeClass('active-parent');

    // hide all top level navigation anchors and buttons from view
    $('.section-nav__list li').each((i, elem) => {
      if (elem.closest('.active') == null) {
        $(elem).children('a').css({
          display: 'none',
        });
        $(elem).children('button').css({
          display: 'none',
        });
      }
    });

    // Show parent element
    //$('.section-nav__list .active').parent().parent().children('a').removeAttr('style');

    // hide back button if at top level of navigation
    if ($('.section-nav__list > li.active').length) {
      $('.section-nav__back').css({
        display: 'none',
      });
    } else {
      $('.section-nav__back').removeAttr('style');
    }

    setTimeout(() => {
      // adding focus after fade animation runs
      $('.section-nav__list .active').children('a').focus();
    }, 300);

    if (!$('.section-nav__list .active').children('ul').length) {
      $('.section-nav__list .active').removeAttr('style');
      $('.section-nav__list .active').parent().addClass('active-parent');
      $('.section-nav__list .active')
        .parent()
        .parent()
        .children('a')
        .removeAttr('style');
    }

    // If active page doesn't have children, show siblings
    if (!$('.section-nav__list .active').children('.list__bttn').length) {
      $('.section-nav__list .active')
        .siblings('li')
        .find('> a, > .list__bttn')
        .removeAttr('style');
    }
  }

  function fade() {
    const $sectionNavList = $('.section-nav__list');

    $sectionNavList.toggleClass('js-z-index');
    $sectionNavList.toggleClass('js-fade');

    setTimeout(() => {
      $sectionNavList.toggleClass('js-fade');
    }, 600);

    // delaying the z-index change until the fade animation has finished running
    setTimeout(() => {
      $sectionNavList.toggleClass('js-z-index');
    }, 900);
  }

  function clickActiveLink() {
    $('.section-nav__list .list__bttn').on('click', (e) => {
      fade();

      // 300ms delay for fade animation to hide elements
      setTimeout(() => {
        const elem = $(e.target);

        $('.section-nav__list li').removeClass('active');
        $('.section-nav__list li').removeAttr('style');
        $('.section-nav__list a').removeAttr('style');
        $('.section-nav__list button').removeAttr('style');

        elem.parent().addClass('active');

        setNavDisplay();
      }, 300);
    });
  }

  function clickBackButton() {
    $('.section-nav__back').on('click', (e) => {
      fade();

      // 300ms delay for fade animation to hide elements
      setTimeout(() => {
        $('.section-nav__list li').removeAttr('style');
        $('.section-nav__list a').removeAttr('style');
        $('.section-nav__list button').removeAttr('style');

        // If bottom level link: no children
        if (!$('.section-nav__list .active').children('a').length) {
          const $activeLink = $('.section-nav__list .active');
          $('.section-nav__list li').removeClass('active');
          $activeLink.parent().parent().parent().addClass('active');
        } else {
          // link with children
          const $activeLink = $('.section-nav__list .active');
          $('.section-nav__list li').removeClass('active');
          $activeLink.parent().parent().addClass('active');
        }

        $('.section-nav__list').removeClass('active');

        setNavDisplay();
      }, 300);
    });
  }

  function sectionToggle() {
    const bttn = $('.section-nav__bttn');

    bttn.on('click', (e) => {
      const dropdown = $(e.target).next();

      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        dropdown.attr('aria-hidden', 'false');
        $('body').addClass('js-interior-z-index');

        if ($(window).width() <= 699) {
          $('html, body').addClass('js-fixed');
          $('html, body').addClass('js-interior-active');
          dropdown.fadeIn(300);
        } else {
          dropdown.slideDown(300);
        }
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        dropdown.attr('aria-hidden', 'true');
        $('html, body').removeClass('js-fixed');
        $('html, body').removeClass('js-interior-active');
        $('body').removeClass('js-interior-z-index');

        if ($(window).width() <= 699) {
          dropdown.fadeOut(300);
        } else {
          dropdown.slideUp(300);
        }
      }
    });

    $(window).on('resize', () => {
      if ($('.section-nav__bttn').attr('aria-expanded') === 'true') {
        if ($(window).width() <= 699) {
          $('html, body').addClass('js-fixed');
          $('html, body').addClass('js-interior-active');
        } else {
          $('html, body').removeClass('js-fixed');
          $('html, body').removeClass('js-interior-active');
        }
      }
    });

    $(document).on('keydown', (e) => {
      if (e.key === 'Escape') {
        if (bttn.attr('aria-expanded') === 'true') {
          bttn.trigger('click');
          bttn.focus();
        }
      }
    });

    $('.section-nav__close').on('click', (e) => {
      bttn.click();
    });

    $(document).on('click', (e) => {
      if (
        !$(e.target).closest('.nav-secondary').length &&
        $('.section-nav__dropdown').is(':visible')
      ) {
        bttn.click();
      }
    });
  }

  function interiorNavKeyboardNavigation(e) {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
    const $focused = $(':focus');
    let $firstFocusableElement = $('.section-nav__back'); // default case: first focusable item is the Back button
    let $lastFocusableElement = $('.list__dropdown li.active > .list__dropdown > li:last-of-type > a + button'); // default case: last focusable item is a button that immediately follows the last link in a list of links

    if ( !$firstFocusableElement.is(':visible') ) { // if the Back button is not visible, then we are at the top level of our interior nav and the first focusable item will be the first link in that list
      $firstFocusableElement = $('.section-nav__list > li.active > a');
    }

    if ( !$lastFocusableElement.length ) { // checking if the last focusable item is a link at the end of a list of links
      $lastFocusableElement = $('.list__dropdown li.active > .list__dropdown > li:last-of-type > a');

      if ( !$lastFocusableElement.length ) { // if the other conditions don't apply then we are at the top level of our interior nav and will check if the last link has a button that immediately follows it
        $lastFocusableElement = $('.section-nav__list li.active > .list__dropdown > li:last-of-type > a + button'); 

        if ( !$lastFocusableElement.length ) { // otherwise the last focusable item is the last link in the top level list of links
          $lastFocusableElement = $('.section-nav__list li.active > .list__dropdown > li:last-of-type > a'); 
        } 
      }
    }

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      // shift + tab was pressed - looping through items backwards
      if ($focused.is($firstFocusableElement)) {
        $lastFocusableElement.focus();
        e.preventDefault();
      }
    } else {
      // tab was pressed - looping through items forwards
      if ($focused.is($lastFocusableElement)) {
        $firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  }

  const init = () => {
    if (!$('.section-nav').length) {
      return;
    }

    $('.current-page').parents('li').addClass('path');

    clickActiveLink();
    clickBackButton();
    setNavDisplay();
    sectionToggle()

    $(document).on('keydown', interiorNavKeyboardNavigation);
  };

  return {
    init,
  };
})();

export default interiorNav;