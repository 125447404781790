/**
 * Blog pages object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if accordion present
 */

 const blog = (() => {
  function toggleFilters() {
    const $blogFiltersContainer = $('.filters-sm__container');
    
    $blogFiltersContainer.toggleClass('js-expanded');
  }

  const init = () => {
    if (!$('.blog-list').length) {
      return;
    }

    $('.filters-sm__btn').on('click', toggleFilters);
  };

  return {
    init,
  };
})();

export default blog;
