const calendar3up = (() => {
  function setupWaypoints() {
    const $calendarItems = Array.prototype.slice.apply(
      document.querySelectorAll('.events3up__item'),
    );

    $calendarItems.forEach((calendarItem) => {
      // eslint-disable-next-line no-undef
      const calendar3upWaypoint = new Waypoint({
        element: calendarItem,
        handler(direction) {
          calendarItem.classList.add('js-animate');
        },
        offset: '70%',
      });
    });
  }

  const init = () => {
    if (!$('.events3up__item').length) {
      return;
    }

    $(window).on('load', setupWaypoints);
  };

  return {
    init,
  };
})();

export default calendar3up;
