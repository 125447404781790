const facultyTable = (() => {
  require('datatables.net-dt')();
  require('datatables.net-buttons')();

  const renameLabel = type => {
    if (type.toUpperCase() === 'NAME & TITLE') {
      return 'type';
    }

    if (type.toUpperCase() === 'OFFICE LOCATION') {
      return 'campusLocation';
    }

    return type.toLowerCase();
  };

  const directoryInit = () => {
    const $tableHolder = $('#faculty-table');
    const ajaxPath = $tableHolder.data('url');

    function renderTable(sortedData) {
      const table = $tableHolder.DataTable({
        data: sortedData,
        processing: true,
        pagingType: 'simple_numbers',
        sPaginationType: 'ellipses',
        deferLoading: 57,
        ordering: false,

        language: {
          search: '<span class="search-title">Search Within Table</span>',
          searchPlaceholder: 'Type to filter table by keyword',
          infoFiltered: '',
          infoEmpty: '',
          info: `<strong>_START_-_END_</strong>
                <span>of</span>
                <strong>_TOTAL_</strong>`,
          sLengthMenu: `<div class="table-length-wrap">
              <select name="table-length" class="table-length form__control">
                <option value="10">Show <span>10</span></option>
                <option value="20">Show <span>20</span></option>
                <option value="30">Show <span>30</span></option>
                <option value="40">Show <span>40</span></option>
                <option value="50">Show <span>50</span></option>
                <option value="-1">All</option>
              </select>
              <span>per page</span>
            </div>`,
        },
        columns: [
          {
            data: 'type',
          },
          {
            data: 'department',
          },
          {
            data: 'email',
            defaultContent: '',
          },
          {
            data: 'phone',
            defaultContent: '',
          },
          {
            data: 'campusLocation',
          },
        ],
        columnDefs: [
          {
            targets: 0,
            data: 'type',
            render(data, type, row) {
              let titleHTML = ``;

              if ( row.title != '' ) {
                const titles = row.title.split('; ');

                titleHTML = `<div>`;
                titles.forEach((title) => {
                  titleHTML += `<span class="title">${title}</span>`;
                });

                titleHTML += `</div>`;
              }

              if (row.url) {
                return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <a class="link-name" href="${row.url}">${row.displayName}</a>
                  ${titleHTML}
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
              }
              return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <span class="link-name" >${row.displayName}</span>
                  ${titleHTML}
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
            },
          },
          {
            targets: 1,
            data: 'department',
            defaultContent: '',
            render(data) {
              if (data) {
                const departments = data.split("; ");
                let rowHTML = `<div class="row-section"><span class="row-label">Department</span>`;

                departments.forEach((department, index) => {
                  rowHTML += `<span class="department">${department}`;

                  // if this is not the last department being listed for the faculty member, we will add a semicolon to the markup.
                  // this code is needed because our DataTables plugin currently filters items in the table by searching for exact matches in text
                  if ( (index + 1) != departments.length ) {
                    rowHTML += `<span class="delimiter">; </span>`;
                  }

                  rowHTML += `</span>`;
                });
                rowHTML += `</div>`;

                return rowHTML;
              }
            },
          },
          {
            targets: 2,
            data: 'email',
            render(data, type, row) {
              if (data) {
                return `<div class="row-section"><span class="row-label">Email</span><a class="email" href="mailto:${row.email}">${data}</a></div>`;
              }
            },
          },
          {
            targets: 3,
            data: 'phone',
            render(data, type, row) {
              if (data) {
                return `<div class="row-section"><span class="row-label">Phone</span><a class="tel" href="tel:${row.phone}">${data}</a></div>`;
              }
            },
          },
          {
            targets: 4,
            data: 'campusLocation',
            render(data, type, row) {
              if (data) {
                return `<div class="row-section"><span class="row-label">Location</span><span class="campusLocation">${data}</span></div>`;
              }
            },
          },
          {
            targets: '_all',
            orderable: false,
          },
        ],
        dom:
          '<"heading-table"B<"heading-table-filter"<"mobile-filter-drop"<"toolbar">rf>>><"wrapper-table"<"table-holder"t>><"footer-table"p<"table-info"li>>',
        buttons: [
          {
            text: 'Filters',
            className: 'filter-btn-drop',
            action: () => {
              const $filterHolder = $('.heading-table .heading-table-filter');
              const $filterToggle = $('.heading-table .filter-btn-drop');

              $filterToggle.toggleClass('expanded');
              $filterHolder
                .stop()
                .slideToggle(300)
                .attr(
                  'aria-hidden',
                  $filterHolder.attr('aria-hidden') === 'false'
                    ? 'true'
                    : 'false',
                );
            },
          },
        ],
        keys: true,
        responsive: true,
        initComplete() {
          this.api()
            .columns()
            .every(function(i) {
              const column = this;
              const info = this.page.info();
              const toolbar = $('.toolbar');
              const holder = document.createElement('div');
              const label = document.createElement('label');
              const searchHolder = $('#faculty-table_filter');
              const select = $(
                '<select class="form__control filter"><option value="">All</option></select>',
              );

              $(label).text(renameLabel(this.header().innerText));

              $(label)
                .addClass('form__label')
                .attr('for', `input-${i}`)
                .appendTo(holder);
              select.attr('id', `input-${i}`).appendTo(holder);
              $(holder)
                .addClass('filter-box')
                .appendTo(toolbar);
              searchHolder.appendTo(toolbar);

              select.on('change', function() {
                const val = $.fn.dataTable.util.escapeRegex($(this).val());

                if (column[0][0] === 1) {
                  if (!val) {
                    column
                      .search(val ? `${val}$` : val, true, true, false)
                      .draw();
                  } else {
                    // Regex below is dependant on the format of the json string
                    // Current format:Department{{ DEPT NAME }}; {{ DEPT NAME }}; {{ DEPT NAME }}
                    // // The string "Department" is added for the mobile layout, and is accounted for as the start of the string
                    // 1st cond: Item is at beginning, with others after it (item ends in ';')
                    // 2nd cond: Item is the only dept, start and end of string
                    // 3rd cond: Item is last dept listed, multiple departments
                    // 4th cond: dept is in the middle of the string
                    const regex = `^Department${val};|^Department${val}$|\\s${val}$|\\s${val};`;
                    column
                      .search(regex, true, false, false)
                      .draw();
                  }
                } else {
                  column.search(val ? `${val}` : val, true, true, false).draw();
                }
              });

              column
                .data()
                .unique()
                .sort()
                .each((d) => {
                  if (d.includes('; ')) {
                    const dataArr = d.split('; ');
                    dataArr.forEach((val) => {
                      const optionExists =
                        $(`option[value="${val}"]`).length > 0;
                      if (!optionExists) {
                        select.append(`<option value="${val}">${val}</option>`);
                      }
                    });
                  } else {
                    const optionExists = $(`option[value="${d}"]`).length > 0;
                    if (!optionExists) {
                      select.append(`<option value="${d}">${d}</option>`);
                    }
                  }
                });
            });
        },
      });

      const renderBtnClear = container => {
        const btnClear = document.createElement('button');
        const iconClear = document.createElement('i');
        const filterHolder = $(container);
        $(btnClear)
          .addClass('clear-table')
          .html('<span>Reset filter</span>');
        $(iconClear)
          .addClass('icon icon-refresh')
          .attr('aria-hidden', 'true');
        $(iconClear).appendTo(btnClear);
        $(btnClear).insertAfter(filterHolder);
      };

      const resetFilter = () => {
        $('.clear-table').on('click', function(e) {
          e.preventDefault();
          $('.filter').prop('selectedIndex', 0);
          table
            .search('')
            .columns()
            .search('')
            .draw();
        });
      };

      renderBtnClear('.dataTables_filter');
      resetFilter();
    }

    $.getJSON(ajaxPath, json => {
      const arr = json;

      // Sort array alphabetically before rendering table
      arr.data.sort(function sortResults(a, b) {
        const nameA = a.lastName.toUpperCase();
        const nameB = b.lastName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      // Convert email string from obfuscated address to readible email address
      for (let i = 0; i < arr.data.length; i++) {
        arr.data[i].email = arr.data[i].email.replace('__at__', '@');
      }

      renderTable(arr.data);
    });
  };

  const init = () => {
    if ($('#faculty-table').length) {
      directoryInit();
    }
  };

  return {
    init,
  };
})();

export default facultyTable;
